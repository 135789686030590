import React from "react"

export const TermsOfServiceData = () => {
  const styles = `      
      ol.lst-kix_7zv18ku5ntgm-0.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-0 0;
      }
      ol.lst-kix_1cpiuy2ebz18-5.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-5 0;
      }
      ol.lst-kix_3zgbyfclaaq4-5.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-5 0;
      }
      .lst-kix_jz0fldkk0rlt-7 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-7;
      }
      ol.lst-kix_u9go6fp1mkff-5.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-5 0;
      }
      .lst-kix_nz5rjffz4jcs-5 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-5;
      }
      ol.lst-kix_c17fprfqji8u-6.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-6 0;
      }
      ol.lst-kix_3rdnq41x41u7-7.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-7 0;
      }
      ol.lst-kix_2h3uyatzokwu-2.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-2 0;
      }
      ol.lst-kix_brm0sqng53jz-3.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-3 0;
      }
      ol.lst-kix_s3bmc1wez3z6-3.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-3 0;
      }
      .lst-kix_3rdnq41x41u7-0 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-0;
      }
      .lst-kix_u9go6fp1mkff-2 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-2;
      }
      .lst-kix_ug5u4nc9zgjp-1 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-1;
      }
      .lst-kix_s3bmc1wez3z6-4 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-4;
      }
      .lst-kix_ihcx011ax0z9-8 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-8;
      }
      .lst-kix_7zv18ku5ntgm-4 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-4;
      }
      ul.lst-kix_gcb2hzmmmkmq-0 {
        list-style-type: none;
      }
      .lst-kix_c17fprfqji8u-6 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-6;
      }
      ol.lst-kix_v3kds1iw7x9c-5.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-5 0;
      }
      ul.lst-kix_gcb2hzmmmkmq-6 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-8 {
        list-style-type: none;
      }
      ul.lst-kix_gcb2hzmmmkmq-5 {
        list-style-type: none;
      }
      ul.lst-kix_gcb2hzmmmkmq-8 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-6 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-7.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-7 0;
      }
      .lst-kix_jz0fldkk0rlt-7 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-7, lower-roman) ". ";
      }
      ul.lst-kix_gcb2hzmmmkmq-7 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-7 {
        list-style-type: none;
      }
      ul.lst-kix_gcb2hzmmmkmq-2 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-4 {
        list-style-type: none;
      }
      ul.lst-kix_gcb2hzmmmkmq-1 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-5 {
        list-style-type: none;
      }
      ul.lst-kix_gcb2hzmmmkmq-4 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-2 {
        list-style-type: none;
      }
      .lst-kix_ug5u4nc9zgjp-8 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-8;
      }
      .lst-kix_jz0fldkk0rlt-6 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-6, lower-latin) ". ";
      }
      ul.lst-kix_gcb2hzmmmkmq-3 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-3 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-5.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-5 0;
      }
      .lst-kix_3zgbyfclaaq4-8 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-8;
      }
      .lst-kix_jz0fldkk0rlt-3 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-3, lower-latin) ". ";
      }
      .lst-kix_jz0fldkk0rlt-5 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-5, decimal) ". ";
      }
      .lst-kix_jz0fldkk0rlt-4 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-4, lower-roman) ". ";
      }
      ol.lst-kix_ihcx011ax0z9-0.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-0 0;
      }
      ol.lst-kix_3zgbyfclaaq4-1 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-0 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-3 {
        list-style-type: none;
      }
      .lst-kix_7zv18ku5ntgm-1 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-1, lower-roman) ". ";
      }
      .lst-kix_jz0fldkk0rlt-1 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-1, lower-roman) ". ";
      }
      ol.lst-kix_3zgbyfclaaq4-2 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-0.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-0 0;
      }
      .lst-kix_7zv18ku5ntgm-8 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-8;
      }
      .lst-kix_7zv18ku5ntgm-0 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-0, lower-latin) ". ";
      }
      .lst-kix_jz0fldkk0rlt-2 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-2, decimal) ". ";
      }
      ol.lst-kix_ug5u4nc9zgjp-0 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-1 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-8 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-0.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-0 0;
      }
      ol.lst-kix_3zgbyfclaaq4-5 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-4 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-7 {
        list-style-type: none;
      }
      .lst-kix_jz0fldkk0rlt-0 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-0, lower-latin) ". ";
      }
      ol.lst-kix_3zgbyfclaaq4-6 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-0 {
        list-style-type: none;
      }
      .lst-kix_jz0fldkk0rlt-0 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-0;
      }
      ol.lst-kix_3rdnq41x41u7-4 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-3 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-2 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-0.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-0 0;
      }
      ol.lst-kix_3rdnq41x41u7-1 {
        list-style-type: none;
      }
      .lst-kix_7zv18ku5ntgm-8 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-8, decimal) ". ";
      }
      .lst-kix_brm0sqng53jz-2 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-2;
      }
      .lst-kix_c17fprfqji8u-2 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-2;
      }
      .lst-kix_v3kds1iw7x9c-2 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-2;
      }
      .lst-kix_7zv18ku5ntgm-2 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-2, decimal) ". ";
      }
      .lst-kix_2h3uyatzokwu-8 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-8;
      }
      .lst-kix_u9go6fp1mkff-6 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-6;
      }
      .lst-kix_7zv18ku5ntgm-3 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-3, lower-latin) ". ";
      }
      .lst-kix_1cpiuy2ebz18-1 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-1;
      }
      .lst-kix_7zv18ku5ntgm-4 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-4, lower-roman) ". ";
      }
      ol.lst-kix_3rdnq41x41u7-8 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-7 {
        list-style-type: none;
      }
      ol.lst-kix_2h3uyatzokwu-7.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-7 0;
      }
      ol.lst-kix_3rdnq41x41u7-6 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-5 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-8.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-8 0;
      }
      .lst-kix_7zv18ku5ntgm-5 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-5, decimal) ". ";
      }
      .lst-kix_7zv18ku5ntgm-7 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-7, lower-roman) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-5.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-5 0;
      }
      .lst-kix_7zv18ku5ntgm-6 > li:before {
        content: "" counter(lst-ctn-kix_7zv18ku5ntgm-6, lower-latin) ". ";
      }
      .lst-kix_jz0fldkk0rlt-8 > li:before {
        content: "" counter(lst-ctn-kix_jz0fldkk0rlt-8, decimal) ". ";
      }
      ol.lst-kix_u9go6fp1mkff-2 {
        list-style-type: none;
      }
      .lst-kix_ademh0z5t091-6 > li:before {
        content: "\x25cf   ";
      }
      .lst-kix_ihcx011ax0z9-0 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-0;
      }
      ol.lst-kix_u9go6fp1mkff-3 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-0 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-1 {
        list-style-type: none;
      }
      .lst-kix_ug5u4nc9zgjp-5 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-5;
      }
      .lst-kix_ademh0z5t091-8 > li:before {
        content: "\x25a0   ";
      }
      ol.lst-kix_nz5rjffz4jcs-6.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-6 0;
      }
      .lst-kix_ademh0z5t091-4 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_s3bmc1wez3z6-8 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-8;
      }
      ol.lst-kix_u9go6fp1mkff-8 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-5.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-5 0;
      }
      ol.lst-kix_u9go6fp1mkff-6 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-7 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-4 {
        list-style-type: none;
      }
      ol.lst-kix_u9go6fp1mkff-5 {
        list-style-type: none;
      }
      .lst-kix_ademh0z5t091-0 > li:before {
        content: "\x25cf   ";
      }
      ol.lst-kix_c17fprfqji8u-1 {
        list-style-type: none;
      }
      .lst-kix_2h3uyatzokwu-4 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-4;
      }
      ol.lst-kix_ihcx011ax0z9-2.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-2 0;
      }
      ol.lst-kix_c17fprfqji8u-2 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-3 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-4 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-5 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-6 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-7 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-8 {
        list-style-type: none;
      }
      .lst-kix_ademh0z5t091-2 > li:before {
        content: "\x25a0   ";
      }
      ol.lst-kix_ug5u4nc9zgjp-6.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-6 0;
      }
      ol.lst-kix_c17fprfqji8u-0 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-0.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-0 0;
      }
      ol.lst-kix_jz0fldkk0rlt-3.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-3 0;
      }
      .lst-kix_c17fprfqji8u-3 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-3;
      }
      ol.lst-kix_u9go6fp1mkff-3.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-3 0;
      }
      .lst-kix_1cpiuy2ebz18-5 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-5;
      }
      .lst-kix_sgqzhjisqw5v-1 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_nz5rjffz4jcs-1 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-1;
      }
      .lst-kix_sgqzhjisqw5v-3 > li:before {
        content: "\x25cf   ";
      }
      .lst-kix_nz5rjffz4jcs-2 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-2;
      }
      ol.lst-kix_s3bmc1wez3z6-6.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-6 0;
      }
      .lst-kix_sgqzhjisqw5v-5 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_nz5rjffz4jcs-8 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-8;
      }
      ol.lst-kix_7zv18ku5ntgm-2.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-2 0;
      }
      ol.lst-kix_nz5rjffz4jcs-4.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-4 0;
      }
      ol.lst-kix_3zgbyfclaaq4-7.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-7 0;
      }
      .lst-kix_sgqzhjisqw5v-7 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_3rdnq41x41u7-7 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-7;
      }
      ol.lst-kix_u9go6fp1mkff-2.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-2 0;
      }
      ol.lst-kix_brm0sqng53jz-8.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-8 0;
      }
      ol.lst-kix_2h3uyatzokwu-4.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-4 0;
      }
      ol.lst-kix_3zgbyfclaaq4-8.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-8 0;
      }
      .lst-kix_s3bmc1wez3z6-3 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-3;
      }
      ol.lst-kix_c17fprfqji8u-1.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-1 0;
      }
      .lst-kix_ihcx011ax0z9-1 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-1;
      }
      ol.lst-kix_ug5u4nc9zgjp-8.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-8 0;
      }
      .lst-kix_3zgbyfclaaq4-1 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-1;
      }
      ol.lst-kix_s3bmc1wez3z6-5.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-5 0;
      }
      .lst-kix_ug5u4nc9zgjp-0 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-0;
      }
      ol.lst-kix_v3kds1iw7x9c-0.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-0 0;
      }
      .lst-kix_2h3uyatzokwu-1 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-1, lower-roman) ". ";
      }
      .lst-kix_nz5rjffz4jcs-4 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-4;
      }
      .lst-kix_1cpiuy2ebz18-6 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-6, decimal) ". ";
      }
      .lst-kix_2h3uyatzokwu-2 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-2, decimal) ". ";
      }
      .lst-kix_1cpiuy2ebz18-7 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-7, lower-latin) ". ";
      }
      .lst-kix_v3kds1iw7x9c-3 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-3, decimal) ". ";
      }
      .lst-kix_7zv18ku5ntgm-5 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-5;
      }
      ol.lst-kix_brm0sqng53jz-6.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-6 0;
      }
      .lst-kix_1cpiuy2ebz18-2 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-2, lower-roman) ") ";
      }
      .lst-kix_v3kds1iw7x9c-4 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-4, lower-latin) ". ";
      }
      .lst-kix_v3kds1iw7x9c-7 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-7, lower-latin) ". ";
      }
      ol.lst-kix_3zgbyfclaaq4-2.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-2 0;
      }
      .lst-kix_1cpiuy2ebz18-3 > li:before {
        content: "(" counter(lst-ctn-kix_1cpiuy2ebz18-3, decimal) ") ";
      }
      .lst-kix_gcb2hzmmmkmq-6 > li:before {
        content: "\x25cf   ";
      }
      .lst-kix_u9go6fp1mkff-0 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-0, lower-latin) ". ";
      }
      .lst-kix_v3kds1iw7x9c-8 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-8, lower-roman) ". ";
      }
      .lst-kix_gcb2hzmmmkmq-5 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_3zgbyfclaaq4-0 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-0;
      }
      .lst-kix_u9go6fp1mkff-5 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-5, decimal) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-7.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-7 0;
      }
      ol.lst-kix_s3bmc1wez3z6-0.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-0 0;
      }
      ol.lst-kix_v3kds1iw7x9c-8.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-8 0;
      }
      ol.lst-kix_v3kds1iw7x9c-2.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-2 0;
      }
      .lst-kix_2h3uyatzokwu-6 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-6, lower-latin) ". ";
      }
      .lst-kix_2h3uyatzokwu-5 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-5, decimal) ". ";
      }
      .lst-kix_u9go6fp1mkff-4 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-4, lower-roman) ". ";
      }
      .lst-kix_gcb2hzmmmkmq-1 > li:before {
        content: "\x25cb   ";
      }
      ol.lst-kix_nz5rjffz4jcs-8.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-8 0;
      }
      .lst-kix_7zv18ku5ntgm-7 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-7;
      }
      .lst-kix_gcb2hzmmmkmq-2 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_u9go6fp1mkff-1 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-1, lower-roman) ". ";
      }
      ol.lst-kix_brm0sqng53jz-0.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-0 0;
      }
      .lst-kix_s3bmc1wez3z6-5 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-5;
      }
      ol.lst-kix_v3kds1iw7x9c-3.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-3 0;
      }
      .lst-kix_jz0fldkk0rlt-4 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-4;
      }
      .lst-kix_u9go6fp1mkff-8 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-8, decimal) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-6.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-6 0;
      }
      .lst-kix_ug5u4nc9zgjp-2 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-2;
      }
      .lst-kix_v3kds1iw7x9c-5 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-5;
      }
      .lst-kix_nz5rjffz4jcs-6 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-6;
      }
      .lst-kix_brm0sqng53jz-5 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-5;
      }
      ol.lst-kix_2h3uyatzokwu-0.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-0 0;
      }
      ol.lst-kix_nz5rjffz4jcs-7.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-7 0;
      }
      ol.lst-kix_brm0sqng53jz-5.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-5 0;
      }
      .lst-kix_2h3uyatzokwu-2 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-2;
      }
      .lst-kix_2h3uyatzokwu-5 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-5;
      }
      .lst-kix_v3kds1iw7x9c-0 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-0, decimal) ". ";
      }
      ol.lst-kix_brm0sqng53jz-2.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-2 0;
      }
      .lst-kix_3rdnq41x41u7-4 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-4;
      }
      .lst-kix_ademh0z5t091-7 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_brm0sqng53jz-7 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-7;
      }
      .lst-kix_nz5rjffz4jcs-7 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-7, lower-roman) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-8.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-8 0;
      }
      .lst-kix_1cpiuy2ebz18-8 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-8;
      }
      .lst-kix_3zgbyfclaaq4-5 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-5;
      }
      .lst-kix_v3kds1iw7x9c-7 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-7;
      }
      .lst-kix_nz5rjffz4jcs-3 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-3, lower-latin) ". ";
      }
      ol.lst-kix_s3bmc1wez3z6-1.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-1 0;
      }
      .lst-kix_c17fprfqji8u-1 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-1;
      }
      .lst-kix_ademh0z5t091-3 > li:before {
        content: "\x25cf   ";
      }
      .lst-kix_ug5u4nc9zgjp-4 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-4;
      }
      ol.lst-kix_ihcx011ax0z9-8.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-8 0;
      }
      .lst-kix_3zgbyfclaaq4-3 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-3;
      }
      ol.lst-kix_ihcx011ax0z9-7 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-8 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-5 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-6 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-3 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-4 {
        list-style-type: none;
      }
      ol.lst-kix_3zgbyfclaaq4-3.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-3 0;
      }
      ol.lst-kix_ihcx011ax0z9-1 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-2 {
        list-style-type: none;
      }
      ol.lst-kix_ihcx011ax0z9-0 {
        list-style-type: none;
      }
      .lst-kix_s3bmc1wez3z6-7 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-7;
      }
      ol.lst-kix_3zgbyfclaaq4-0.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-0 0;
      }
      .lst-kix_2h3uyatzokwu-0 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-0;
      }
      .lst-kix_sgqzhjisqw5v-0 > li:before {
        content: "\x25cf   ";
      }
      ol.lst-kix_v3kds1iw7x9c-7.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-7 0;
      }
      .lst-kix_sgqzhjisqw5v-4 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_sgqzhjisqw5v-8 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_ihcx011ax0z9-1 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-1, lower-roman) ". ";
      }
      .lst-kix_s3bmc1wez3z6-2 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-2, decimal) ". ";
      }
      .lst-kix_2h3uyatzokwu-7 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-7;
      }
      ol.lst-kix_brm0sqng53jz-1.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-1 0;
      }
      .lst-kix_7zv18ku5ntgm-0 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-0;
      }
      ol.lst-kix_v3kds1iw7x9c-6.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-6 0;
      }
      .lst-kix_ihcx011ax0z9-4 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-4;
      }
      .lst-kix_ug5u4nc9zgjp-2 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-2, decimal) ". ";
      }
      .lst-kix_s3bmc1wez3z6-0 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-0;
      }
      .lst-kix_ug5u4nc9zgjp-6 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-6, lower-latin) ". ";
      }
      .lst-kix_s3bmc1wez3z6-6 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-6, lower-latin) ". ";
      }
      .lst-kix_jz0fldkk0rlt-2 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-2;
      }
      .lst-kix_ihcx011ax0z9-5 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-5, decimal) ". ";
      }
      ol.lst-kix_3zgbyfclaaq4-1.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-1 0;
      }
      .lst-kix_brm0sqng53jz-8 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-8, decimal) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-5 {
        list-style-type: none;
      }
      .lst-kix_c17fprfqji8u-1 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-1, lower-roman) ". ";
      }
      .lst-kix_c17fprfqji8u-2 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-2, decimal) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-6 {
        list-style-type: none;
      }
      .lst-kix_brm0sqng53jz-7 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-7, lower-roman) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-3 {
        list-style-type: none;
      }
      ol.lst-kix_2h3uyatzokwu-4 {
        list-style-type: none;
      }
      .lst-kix_s3bmc1wez3z6-2 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-2;
      }
      .lst-kix_brm0sqng53jz-6 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-6, lower-latin) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-1 {
        list-style-type: none;
      }
      .lst-kix_c17fprfqji8u-0 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-0, lower-latin) ". ";
      }
      .lst-kix_c17fprfqji8u-3 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-3, lower-latin) ". ";
      }
      .lst-kix_c17fprfqji8u-4 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-4, lower-roman) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-2 {
        list-style-type: none;
      }
      ol.lst-kix_2h3uyatzokwu-0 {
        list-style-type: none;
      }
      .lst-kix_brm0sqng53jz-4 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-4, lower-roman) ". ";
      }
      .lst-kix_brm0sqng53jz-3 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-3, lower-latin) ". ";
      }
      .lst-kix_brm0sqng53jz-5 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-5, decimal) ". ";
      }
      .lst-kix_ihcx011ax0z9-6 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-6;
      }
      .lst-kix_v3kds1iw7x9c-0 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-0;
      }
      ol.lst-kix_nz5rjffz4jcs-8 {
        list-style-type: none;
      }
      .lst-kix_u9go6fp1mkff-4 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-4;
      }
      .lst-kix_1cpiuy2ebz18-3 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-3;
      }
      ol.lst-kix_ug5u4nc9zgjp-7.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-7 0;
      }
      ul.lst-kix_sgqzhjisqw5v-0 {
        list-style-type: none;
      }
      ol.lst-kix_nz5rjffz4jcs-5.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-5 0;
      }
      ol.lst-kix_jz0fldkk0rlt-4.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-4 0;
      }
      ol.lst-kix_ihcx011ax0z9-1.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-1 0;
      }
      .lst-kix_brm0sqng53jz-0 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-0;
      }
      .lst-kix_brm0sqng53jz-0 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-0, lower-latin) ". ";
      }
      .lst-kix_brm0sqng53jz-1 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-1, lower-roman) ". ";
      }
      .lst-kix_brm0sqng53jz-2 > li:before {
        content: "" counter(lst-ctn-kix_brm0sqng53jz-2, decimal) ". ";
      }
      ul.lst-kix_sgqzhjisqw5v-8 {
        list-style-type: none;
      }
      ul.lst-kix_sgqzhjisqw5v-7 {
        list-style-type: none;
      }
      ul.lst-kix_sgqzhjisqw5v-6 {
        list-style-type: none;
      }
      ul.lst-kix_sgqzhjisqw5v-5 {
        list-style-type: none;
      }
      ul.lst-kix_sgqzhjisqw5v-4 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-6.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-6 0;
      }
      ul.lst-kix_sgqzhjisqw5v-3 {
        list-style-type: none;
      }
      ol.lst-kix_3rdnq41x41u7-1.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-1 0;
      }
      ul.lst-kix_sgqzhjisqw5v-2 {
        list-style-type: none;
      }
      ul.lst-kix_sgqzhjisqw5v-1 {
        list-style-type: none;
      }
      .lst-kix_3zgbyfclaaq4-5 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-5, decimal) ". ";
      }
      .lst-kix_3zgbyfclaaq4-4 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-4, lower-roman) ". ";
      }
      .lst-kix_3zgbyfclaaq4-6 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-6, lower-latin) ". ";
      }
      .lst-kix_3zgbyfclaaq4-3 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-3, lower-latin) ". ";
      }
      .lst-kix_3zgbyfclaaq4-7 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-7, lower-roman) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-8.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-8 0;
      }
      .lst-kix_3zgbyfclaaq4-1 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-1, lower-roman) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-1.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-1 0;
      }
      .lst-kix_3zgbyfclaaq4-0 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-0, lower-latin) ". ";
      }
      .lst-kix_3zgbyfclaaq4-2 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-2, decimal) ". ";
      }
      .lst-kix_3zgbyfclaaq4-8 > li:before {
        content: "" counter(lst-ctn-kix_3zgbyfclaaq4-8, decimal) ". ";
      }
      ol.lst-kix_u9go6fp1mkff-6.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-6 0;
      }
      ol.lst-kix_s3bmc1wez3z6-2.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-2 0;
      }
      .lst-kix_c17fprfqji8u-8 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-8;
      }
      ol.lst-kix_7zv18ku5ntgm-1 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-0 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-7 {
        list-style-type: none;
      }
      .lst-kix_3rdnq41x41u7-0 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-0, lower-latin) ". ";
      }
      ol.lst-kix_7zv18ku5ntgm-6 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-8 {
        list-style-type: none;
      }
      ol.lst-kix_brm0sqng53jz-4.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-4 0;
      }
      ol.lst-kix_7zv18ku5ntgm-3 {
        list-style-type: none;
      }
      .lst-kix_3rdnq41x41u7-1 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-1, lower-roman) ". ";
      }
      ol.lst-kix_7zv18ku5ntgm-2 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-5 {
        list-style-type: none;
      }
      .lst-kix_7zv18ku5ntgm-2 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-2;
      }
      ol.lst-kix_7zv18ku5ntgm-4 {
        list-style-type: none;
      }
      .lst-kix_3rdnq41x41u7-3 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-3, lower-latin) ". ";
      }
      .lst-kix_3rdnq41x41u7-4 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-4, lower-roman) ". ";
      }
      ol.lst-kix_ihcx011ax0z9-6.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-6 0;
      }
      ol.lst-kix_v3kds1iw7x9c-4.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-4 0;
      }
      .lst-kix_3rdnq41x41u7-2 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-2, decimal) ". ";
      }
      .lst-kix_3rdnq41x41u7-6 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-6, lower-latin) ". ";
      }
      .lst-kix_nz5rjffz4jcs-3 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-3;
      }
      ol.lst-kix_1cpiuy2ebz18-6.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-6 0;
      }
      .lst-kix_3rdnq41x41u7-5 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-5, decimal) ". ";
      }
      ol.lst-kix_3zgbyfclaaq4-4.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-4 0;
      }
      .lst-kix_1cpiuy2ebz18-7 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-7;
      }
      .lst-kix_3rdnq41x41u7-2 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-2;
      }
      .lst-kix_u9go6fp1mkff-0 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-0;
      }
      .lst-kix_nz5rjffz4jcs-0 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-0, lower-latin) ". ";
      }
      .lst-kix_3rdnq41x41u7-5 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-5;
      }
      .lst-kix_c17fprfqji8u-7 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-7, lower-roman) ". ";
      }
      .lst-kix_c17fprfqji8u-8 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-8, decimal) ". ";
      }
      ol.lst-kix_3rdnq41x41u7-6.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-6 0;
      }
      .lst-kix_3rdnq41x41u7-7 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-7, lower-roman) ". ";
      }
      .lst-kix_c17fprfqji8u-5 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-5, decimal) ". ";
      }
      .lst-kix_c17fprfqji8u-6 > li:before {
        content: "" counter(lst-ctn-kix_c17fprfqji8u-6, lower-latin) ". ";
      }
      ol.lst-kix_nz5rjffz4jcs-0.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-0 0;
      }
      .lst-kix_3rdnq41x41u7-8 > li:before {
        content: "" counter(lst-ctn-kix_3rdnq41x41u7-8, decimal) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-7 {
        list-style-type: none;
      }
      ol.lst-kix_2h3uyatzokwu-8 {
        list-style-type: none;
      }
      .lst-kix_nz5rjffz4jcs-8 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-8, decimal) ". ";
      }
      ol.lst-kix_2h3uyatzokwu-3.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-3 0;
      }
      ol.lst-kix_u9go6fp1mkff-1.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-1 0;
      }
      ol.lst-kix_3rdnq41x41u7-8.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-8 0;
      }
      .lst-kix_2h3uyatzokwu-3 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-3;
      }
      .lst-kix_nz5rjffz4jcs-2 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-2, decimal) ". ";
      }
      ol.lst-kix_s3bmc1wez3z6-7.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-7 0;
      }
      ol.lst-kix_2h3uyatzokwu-6.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-6 0;
      }
      .lst-kix_nz5rjffz4jcs-4 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-4, lower-roman) ". ";
      }
      .lst-kix_nz5rjffz4jcs-6 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-6, lower-latin) ". ";
      }
      ol.lst-kix_s3bmc1wez3z6-4.start {
        counter-reset: lst-ctn-kix_s3bmc1wez3z6-4 0;
      }
      ol.lst-kix_v3kds1iw7x9c-1.start {
        counter-reset: lst-ctn-kix_v3kds1iw7x9c-1 0;
      }
      ol.lst-kix_3zgbyfclaaq4-6.start {
        counter-reset: lst-ctn-kix_3zgbyfclaaq4-6 0;
      }
      .lst-kix_u9go6fp1mkff-8 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-8;
      }
      .lst-kix_3rdnq41x41u7-6 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-6;
      }
      .lst-kix_3zgbyfclaaq4-6 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-6;
      }
      ol.lst-kix_c17fprfqji8u-2.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-2 0;
      }
      .lst-kix_ug5u4nc9zgjp-7 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-7;
      }
      ol.lst-kix_u9go6fp1mkff-4.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-4 0;
      }
      .lst-kix_ihcx011ax0z9-2 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-2;
      }
      .lst-kix_nz5rjffz4jcs-7 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-7;
      }
      .lst-kix_3rdnq41x41u7-1 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-1;
      }
      ol.lst-kix_s3bmc1wez3z6-2 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-1 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-0 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-0.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-0 0;
      }
      .lst-kix_v3kds1iw7x9c-4 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-4;
      }
      ol.lst-kix_s3bmc1wez3z6-6 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-5 {
        list-style-type: none;
      }
      .lst-kix_u9go6fp1mkff-7 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-7;
      }
      ol.lst-kix_brm0sqng53jz-7.start {
        counter-reset: lst-ctn-kix_brm0sqng53jz-7 0;
      }
      ol.lst-kix_s3bmc1wez3z6-4 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-3 {
        list-style-type: none;
      }
      .lst-kix_s3bmc1wez3z6-5 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-5, decimal) ". ";
      }
      ol.lst-kix_s3bmc1wez3z6-8 {
        list-style-type: none;
      }
      ol.lst-kix_s3bmc1wez3z6-7 {
        list-style-type: none;
      }
      .lst-kix_v3kds1iw7x9c-3 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-3;
      }
      ol.lst-kix_ihcx011ax0z9-4.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-4 0;
      }
      .lst-kix_ihcx011ax0z9-2 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-2, decimal) ". ";
      }
      .lst-kix_1cpiuy2ebz18-0 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-0;
      }
      .lst-kix_s3bmc1wez3z6-3 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-3, lower-latin) ". ";
      }
      .lst-kix_ihcx011ax0z9-0 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-0, lower-latin) ". ";
      }
      .lst-kix_s3bmc1wez3z6-1 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-1, lower-roman) ". ";
      }
      .lst-kix_u9go6fp1mkff-1 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-1;
      }
      .lst-kix_brm0sqng53jz-4 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-4;
      }
      .lst-kix_1cpiuy2ebz18-6 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-6;
      }
      ol.lst-kix_nz5rjffz4jcs-0 {
        list-style-type: none;
      }
      ol.lst-kix_nz5rjffz4jcs-1 {
        list-style-type: none;
      }
      ol.lst-kix_nz5rjffz4jcs-2 {
        list-style-type: none;
      }
      .lst-kix_7zv18ku5ntgm-3 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-3;
      }
      ol.lst-kix_nz5rjffz4jcs-3 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-2.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-2 0;
      }
      ol.lst-kix_nz5rjffz4jcs-4 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-1.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-1 0;
      }
      ol.lst-kix_nz5rjffz4jcs-5 {
        list-style-type: none;
      }
      ol.lst-kix_nz5rjffz4jcs-6 {
        list-style-type: none;
      }
      ol.lst-kix_nz5rjffz4jcs-7 {
        list-style-type: none;
      }
      .lst-kix_c17fprfqji8u-4 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-4;
      }
      ol.lst-kix_nz5rjffz4jcs-3.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-3 0;
      }
      .lst-kix_ug5u4nc9zgjp-1 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-1, lower-roman) ". ";
      }
      .lst-kix_ug5u4nc9zgjp-6 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-6;
      }
      .lst-kix_s3bmc1wez3z6-7 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-7, lower-roman) ". ";
      }
      .lst-kix_ug5u4nc9zgjp-7 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-7, lower-roman) ". ";
      }
      .lst-kix_ihcx011ax0z9-4 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-4, lower-roman) ". ";
      }
      .lst-kix_jz0fldkk0rlt-5 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-5;
      }
      ol.lst-kix_ihcx011ax0z9-3.start {
        counter-reset: lst-ctn-kix_ihcx011ax0z9-3 0;
      }
      .lst-kix_ug5u4nc9zgjp-3 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-3, lower-latin) ". ";
      }
      .lst-kix_ihcx011ax0z9-6 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-6, lower-latin) ". ";
      }
      .lst-kix_ihcx011ax0z9-8 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-8, decimal) ". ";
      }
      .lst-kix_ug5u4nc9zgjp-5 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-5, decimal) ". ";
      }
      .lst-kix_3zgbyfclaaq4-7 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-7;
      }
      ol.lst-kix_jz0fldkk0rlt-3 {
        list-style-type: none;
      }
      .lst-kix_brm0sqng53jz-1 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-1;
      }
      ol.lst-kix_jz0fldkk0rlt-4 {
        list-style-type: none;
      }
      .lst-kix_1cpiuy2ebz18-8 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-8, lower-roman) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-5 {
        list-style-type: none;
      }
      .lst-kix_2h3uyatzokwu-0 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-0, lower-latin) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-6 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-0 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-1 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-2 {
        list-style-type: none;
      }
      .lst-kix_2h3uyatzokwu-3 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-3, lower-latin) ". ";
      }
      .lst-kix_2h3uyatzokwu-4 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-4, lower-roman) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-7 {
        list-style-type: none;
      }
      ol.lst-kix_jz0fldkk0rlt-8 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-2.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-2 0;
      }
      .lst-kix_1cpiuy2ebz18-2 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-2;
      }
      .lst-kix_1cpiuy2ebz18-0 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-0, decimal) ") ";
      }
      .lst-kix_1cpiuy2ebz18-1 > li:before {
        content: "" counter(lst-ctn-kix_1cpiuy2ebz18-1, lower-latin) ") ";
      }
      .lst-kix_c17fprfqji8u-5 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-5;
      }
      ol.lst-kix_1cpiuy2ebz18-8.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-8 0;
      }
      .lst-kix_1cpiuy2ebz18-4 > li:before {
        content: "(" counter(lst-ctn-kix_1cpiuy2ebz18-4, lower-latin) ") ";
      }
      .lst-kix_1cpiuy2ebz18-5 > li:before {
        content: "(" counter(lst-ctn-kix_1cpiuy2ebz18-5, lower-roman) ") ";
      }
      .lst-kix_ihcx011ax0z9-7 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-7;
      }
      .lst-kix_v3kds1iw7x9c-5 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-5, lower-roman) ". ";
      }
      ol.lst-kix_jz0fldkk0rlt-1.start {
        counter-reset: lst-ctn-kix_jz0fldkk0rlt-1 0;
      }
      ol.lst-kix_u9go6fp1mkff-8.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-8 0;
      }
      .lst-kix_v3kds1iw7x9c-6 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-6, decimal) ". ";
      }
      .lst-kix_gcb2hzmmmkmq-8 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_jz0fldkk0rlt-6 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-6;
      }
      .lst-kix_u9go6fp1mkff-5 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-5;
      }
      .lst-kix_u9go6fp1mkff-6 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-6, lower-latin) ". ";
      }
      .lst-kix_gcb2hzmmmkmq-7 > li:before {
        content: "\x25cb   ";
      }
      .lst-kix_2h3uyatzokwu-7 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-7, lower-roman) ". ";
      }
      ol.lst-kix_3rdnq41x41u7-4.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-4 0;
      }
      ol.lst-kix_brm0sqng53jz-8 {
        list-style-type: none;
      }
      ol.lst-kix_2h3uyatzokwu-5.start {
        counter-reset: lst-ctn-kix_2h3uyatzokwu-5 0;
      }
      .lst-kix_2h3uyatzokwu-8 > li:before {
        content: "" counter(lst-ctn-kix_2h3uyatzokwu-8, decimal) ". ";
      }
      ol.lst-kix_brm0sqng53jz-7 {
        list-style-type: none;
      }
      .lst-kix_gcb2hzmmmkmq-0 > li:before {
        content: "\x25cf   ";
      }
      ol.lst-kix_nz5rjffz4jcs-2.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-2 0;
      }
      .lst-kix_brm0sqng53jz-3 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-3;
      }
      .lst-kix_s3bmc1wez3z6-1 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-1;
      }
      ol.lst-kix_7zv18ku5ntgm-3.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-3 0;
      }
      .lst-kix_u9go6fp1mkff-3 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-3, lower-latin) ". ";
      }
      ol.lst-kix_brm0sqng53jz-2 {
        list-style-type: none;
      }
      ol.lst-kix_brm0sqng53jz-1 {
        list-style-type: none;
      }
      .lst-kix_v3kds1iw7x9c-1 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-1;
      }
      ol.lst-kix_brm0sqng53jz-0 {
        list-style-type: none;
      }
      .lst-kix_gcb2hzmmmkmq-4 > li:before {
        content: "\x25cb   ";
      }
      ol.lst-kix_brm0sqng53jz-6 {
        list-style-type: none;
      }
      ol.lst-kix_brm0sqng53jz-5 {
        list-style-type: none;
      }
      .lst-kix_ihcx011ax0z9-5 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-5;
      }
      .lst-kix_u9go6fp1mkff-2 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-2, decimal) ". ";
      }
      ol.lst-kix_brm0sqng53jz-4 {
        list-style-type: none;
      }
      ol.lst-kix_brm0sqng53jz-3 {
        list-style-type: none;
      }
      .lst-kix_gcb2hzmmmkmq-3 > li:before {
        content: "\x25cf   ";
      }
      ol.lst-kix_ug5u4nc9zgjp-5.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-5 0;
      }
      ol.lst-kix_1cpiuy2ebz18-7 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-6 {
        list-style-type: none;
      }
      .lst-kix_ihcx011ax0z9-3 > li {
        counter-increment: lst-ctn-kix_ihcx011ax0z9-3;
      }
      ol.lst-kix_1cpiuy2ebz18-8 {
        list-style-type: none;
      }
      .lst-kix_7zv18ku5ntgm-1 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-1;
      }
      ol.lst-kix_1cpiuy2ebz18-7.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-7 0;
      }
      .lst-kix_u9go6fp1mkff-7 > li:before {
        content: "" counter(lst-ctn-kix_u9go6fp1mkff-7, lower-roman) ". ";
      }
      ol.lst-kix_c17fprfqji8u-3.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-3 0;
      }
      ol.lst-kix_nz5rjffz4jcs-1.start {
        counter-reset: lst-ctn-kix_nz5rjffz4jcs-1 0;
      }
      .lst-kix_3zgbyfclaaq4-2 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-2;
      }
      .lst-kix_nz5rjffz4jcs-0 > li {
        counter-increment: lst-ctn-kix_nz5rjffz4jcs-0;
      }
      .lst-kix_v3kds1iw7x9c-8 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-8;
      }
      ol.lst-kix_ug5u4nc9zgjp-4.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-4 0;
      }
      .lst-kix_brm0sqng53jz-8 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-8;
      }
      .lst-kix_3rdnq41x41u7-8 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-8;
      }
      ol.lst-kix_7zv18ku5ntgm-4.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-4 0;
      }
      .lst-kix_v3kds1iw7x9c-2 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-2, lower-roman) ". ";
      }
      .lst-kix_u9go6fp1mkff-3 > li {
        counter-increment: lst-ctn-kix_u9go6fp1mkff-3;
      }
      .lst-kix_v3kds1iw7x9c-1 > li:before {
        content: "" counter(lst-ctn-kix_v3kds1iw7x9c-1, lower-latin) ". ";
      }
      ol.lst-kix_v3kds1iw7x9c-0 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-1 {
        list-style-type: none;
      }
      .lst-kix_1cpiuy2ebz18-4 > li {
        counter-increment: lst-ctn-kix_1cpiuy2ebz18-4;
      }
      ol.lst-kix_v3kds1iw7x9c-4 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-5 {
        list-style-type: none;
      }
      ol.lst-kix_c17fprfqji8u-4.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-4 0;
      }
      ol.lst-kix_v3kds1iw7x9c-2 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-3 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-8 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-6 {
        list-style-type: none;
      }
      ol.lst-kix_v3kds1iw7x9c-7 {
        list-style-type: none;
      }
      .lst-kix_c17fprfqji8u-0 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-0;
      }
      ul.lst-kix_ademh0z5t091-3 {
        list-style-type: none;
      }
      ul.lst-kix_ademh0z5t091-4 {
        list-style-type: none;
      }
      ul.lst-kix_ademh0z5t091-5 {
        list-style-type: none;
      }
      .lst-kix_ademh0z5t091-5 > li:before {
        content: "\x25a0   ";
      }
      ul.lst-kix_ademh0z5t091-6 {
        list-style-type: none;
      }
      ol.lst-kix_7zv18ku5ntgm-7.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-7 0;
      }
      ul.lst-kix_ademh0z5t091-0 {
        list-style-type: none;
      }
      ul.lst-kix_ademh0z5t091-1 {
        list-style-type: none;
      }
      ul.lst-kix_ademh0z5t091-2 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-0.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-0 0;
      }
      ol.lst-kix_c17fprfqji8u-5.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-5 0;
      }
      ul.lst-kix_ademh0z5t091-7 {
        list-style-type: none;
      }
      .lst-kix_ug5u4nc9zgjp-8 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-8, decimal) ". ";
      }
      ul.lst-kix_ademh0z5t091-8 {
        list-style-type: none;
      }
      .lst-kix_nz5rjffz4jcs-1 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-1, lower-roman) ". ";
      }
      ol.lst-kix_3rdnq41x41u7-5.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-5 0;
      }
      .lst-kix_nz5rjffz4jcs-5 > li:before {
        content: "" counter(lst-ctn-kix_nz5rjffz4jcs-5, decimal) ". ";
      }
      .lst-kix_ademh0z5t091-1 > li:before {
        content: "\x25cb   ";
      }
      ol.lst-kix_c17fprfqji8u-8.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-8 0;
      }
      .lst-kix_v3kds1iw7x9c-6 > li {
        counter-increment: lst-ctn-kix_v3kds1iw7x9c-6;
      }
      .lst-kix_jz0fldkk0rlt-3 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-3;
      }
      ol.lst-kix_u9go6fp1mkff-7.start {
        counter-reset: lst-ctn-kix_u9go6fp1mkff-7 0;
      }
      .lst-kix_3rdnq41x41u7-3 > li {
        counter-increment: lst-ctn-kix_3rdnq41x41u7-3;
      }
      .lst-kix_brm0sqng53jz-6 > li {
        counter-increment: lst-ctn-kix_brm0sqng53jz-6;
      }
      .lst-kix_jz0fldkk0rlt-1 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-1;
      }
      ol.lst-kix_1cpiuy2ebz18-1.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-1 0;
      }
      ol.lst-kix_1cpiuy2ebz18-3 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-2 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-4.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-4 0;
      }
      ol.lst-kix_1cpiuy2ebz18-5 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-4 {
        list-style-type: none;
      }
      ol.lst-kix_ug5u4nc9zgjp-3.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-3 0;
      }
      ol.lst-kix_1cpiuy2ebz18-1 {
        list-style-type: none;
      }
      ol.lst-kix_1cpiuy2ebz18-0 {
        list-style-type: none;
      }
      .lst-kix_s3bmc1wez3z6-0 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-0, lower-latin) ". ";
      }
      .lst-kix_2h3uyatzokwu-6 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-6;
      }
      ol.lst-kix_3rdnq41x41u7-3.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-3 0;
      }
      .lst-kix_sgqzhjisqw5v-2 > li:before {
        content: "\x25a0   ";
      }
      .lst-kix_s3bmc1wez3z6-4 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-4, lower-roman) ". ";
      }
      .lst-kix_sgqzhjisqw5v-6 > li:before {
        content: "\x25cf   ";
      }
      ol.lst-kix_ug5u4nc9zgjp-2.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-2 0;
      }
      ol.lst-kix_1cpiuy2ebz18-3.start {
        counter-reset: lst-ctn-kix_1cpiuy2ebz18-3 0;
      }
      .lst-kix_2h3uyatzokwu-1 > li {
        counter-increment: lst-ctn-kix_2h3uyatzokwu-1;
      }
      .lst-kix_s3bmc1wez3z6-6 > li {
        counter-increment: lst-ctn-kix_s3bmc1wez3z6-6;
      }
      ol.lst-kix_3rdnq41x41u7-2.start {
        counter-reset: lst-ctn-kix_3rdnq41x41u7-2 0;
      }
      ol.lst-kix_7zv18ku5ntgm-8.start {
        counter-reset: lst-ctn-kix_7zv18ku5ntgm-8 0;
      }
      .lst-kix_c17fprfqji8u-7 > li {
        counter-increment: lst-ctn-kix_c17fprfqji8u-7;
      }
      ol.lst-kix_ug5u4nc9zgjp-1.start {
        counter-reset: lst-ctn-kix_ug5u4nc9zgjp-1 0;
      }
      .lst-kix_ug5u4nc9zgjp-0 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-0, lower-latin) ". ";
      }
      li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        min-width: 18pt;
      }
      .lst-kix_7zv18ku5ntgm-6 > li {
        counter-increment: lst-ctn-kix_7zv18ku5ntgm-6;
      }
      .lst-kix_ihcx011ax0z9-3 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-3, lower-latin) ". ";
      }
      .lst-kix_jz0fldkk0rlt-8 > li {
        counter-increment: lst-ctn-kix_jz0fldkk0rlt-8;
      }
      .lst-kix_3zgbyfclaaq4-4 > li {
        counter-increment: lst-ctn-kix_3zgbyfclaaq4-4;
      }
      .lst-kix_s3bmc1wez3z6-8 > li:before {
        content: "" counter(lst-ctn-kix_s3bmc1wez3z6-8, decimal) ". ";
      }
      .lst-kix_ug5u4nc9zgjp-3 > li {
        counter-increment: lst-ctn-kix_ug5u4nc9zgjp-3;
      }
      ol.lst-kix_c17fprfqji8u-7.start {
        counter-reset: lst-ctn-kix_c17fprfqji8u-7 0;
      }
      .lst-kix_ug5u4nc9zgjp-4 > li:before {
        content: "" counter(lst-ctn-kix_ug5u4nc9zgjp-4, lower-roman) ". ";
      }
      .lst-kix_ihcx011ax0z9-7 > li:before {
        content: "" counter(lst-ctn-kix_ihcx011ax0z9-7, lower-roman) ". ";
      }
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c7 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c13 {
        padding-top: 20pt;
        padding-bottom: 6pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c14 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 20pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c5 {
        padding-top: 18pt;
        padding-bottom: 6pt;
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c4 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 11pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c3 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 16pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c0 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .c8 {
        text-decoration-skip-ink: none;
        -webkit-text-decoration-skip: none;
        color: #1155cc;
        text-decoration: underline;
      }
      .c11 {
        background-color: #ffffff;
        max-width: 468pt;
        padding: 72pt 72pt 72pt 72pt;
      }
      .c12 {
        color: inherit;
        text-decoration: inherit;
      }
      .c1 {
        margin-left: 36pt;
        padding-left: 0pt;
      }
      .c6 {
        padding: 0;
        margin: 0;
      }
      .c10 {
        margin-left: 72pt;
        padding-left: 0pt;
      }
      .c9 {
        font-weight: 700;
      }
      .c2 {
        height: 11pt;
      }
      .title {
        padding-top: 0pt;
        color: #000000;
        font-size: 26pt;
        padding-bottom: 3pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 0pt;
        color: #666666;
        font-size: 15pt;
        padding-bottom: 16pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 20pt;
        color: #000000;
        font-size: 20pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-size: 16pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 16pt;
        color: #434343;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 14pt;
        color: #666666;
        font-size: 12pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 12pt;
        color: #666666;
        font-size: 11pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    `

  return (
    <div>
      <style type="text/css">{styles}</style>
      <h1 class="c13" id="h.1ozhblqsbyl6">
        <span class="c14">Terms of Service</span>
      </h1>
      <p class="c0">
        <span class="c9">Sideline Sports a Islandi </span>
        <span class="c9">ehf</span>
        <span class="c7">
          , org. no. 520601-2790 (&ldquo;Sideline Sports&rdquo;) provides
          digital services in the form of website, mobile and desktop
          applications for registering members, enabling coaching organization,
          communication and session scheduling and other sport club and coaching
          functions to sports clubs, other organizations and personal trainers
          (the &ldquo;Customer&rdquo;).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          These Terms of Service contain the terms and conditions that govern
          the use of Sideline Sports&rsquo; Platform (as defined below) and all
          content, services and/or products available on or through the Platform
          (collectively, the &ldquo;Sideline Services&rdquo;). In these Terms of
          Service we refer to Sideline Sports as &ldquo;us&rdquo; and the
          Customer as &ldquo;you&rdquo;, as applicable.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          The Sideline Services are offered to you subject to your acceptance,
          without modification (other than Special Terms (as defined below)
          agreed by the parties pursuant to these Terms of Service), of all of
          the terms and conditions contained herein and all other operating
          rules, policies (including, without limitation, our Privacy Policy at
          www.sidelinesports.com/privacyPolicy), and any future modifications
          thereof, and procedures that may be published from time to time on the
          Platform or made available to you on or through the Sideline Services
          (collectively, the &ldquo;Terms&rdquo;). When accepted by you (as
          defined below), these Terms form a legally binding contract between
          you and Supplier (as defined below). If you are entering into these
          Terms on behalf of an entity, such as your employer or the company you
          work for, you represent that you have the legal authority to bind that
          entity.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          PLEASE READ THESE TERMS CAREFULLY. BY REGISTERING FOR, ACCESSING,
          BROWSING, AND/OR OTHERWISE USING THE SIDELINE SERVICES, YOU
          ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY
          THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS, DO NOT
          ACCESS, BROWSE OR OTHERWISE USE THE PLATFORM OR THE SIDELINE SERVICES.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Sideline Sports may, in its sole discretion, elect to suspend or
          terminate access to, or use of the Sideline Services to anyone who
          violates these Terms.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          If you register for a free trial of the Sideline Services, the
          applicable provisions of these Terms will govern that free trial.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          The original language of these Terms is English. Sideline Sports may
          make available translations for convenience. In case of conflicts
          between the original English version and any translation, the English
          version shall prevail.
        </span>
      </p>
      <h2 class="c5" id="h.5colq5pyaf9f">
        <span class="c3">1. Definitions</span>
      </h2>
      <p class="c0">
        <span class="c7">Account</span>
      </p>
      <p class="c0">
        <span>T</span>
        <span class="c4">
          he primary means for accessing and using the Sideline Services,
          subject to payment of a Fee designated in the selected License;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c7"></span>
      </p>
      <p class="c0">
        <span class="c7">Activity</span>
      </p>
      <p class="c0">
        <span class="c4">
          Practices, games, game events, workouts, tests, events, drills,
          exercises, game events, golf rounds and other achievements and doings
          that can be associated with a person or a team;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Customer</span>
      </p>
      <p class="c0">
        <span class="c4">
          Sport club, organization or person who obtains an Account and enters a
          business relationship with Sideline.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Fee</span>
      </p>
      <p class="c0">
        <span class="c4">Payment for using the activated Account;</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Free Trial</span>
      </p>
      <p class="c0">
        <span class="c4">
          Temporary access for the purposes of trying out the Platform in
          accordance with any selected license without paying a fee;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Guidelines</span>
      </p>
      <p class="c0">
        <span class="c4">
          Additional guidelines or rules applicable to specific features,
          applications, products, or services which may be posted from time to
          time on the Platform or otherwise made available on or through the
          Sideline Services;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">License</span>
      </p>
      <p class="c0">
        <span class="c4">
          various criteria related to the use and functionality of the Sideline
          Services and on which the Fee is based;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Platform</span>
      </p>
      <p class="c0">
        <span class="c4">
          the Sideline Sports applications; XPS Network (desktop application,
          mobile apps, including white-labeled apps, web browser version),
          including but not limited to XPS Network, XPS Client, XPS Tags, XPS
          Live Tags, XPS Nutrition
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Sideline [Sports]</span>
      </p>
      <p class="c0">
        <span>Sideline Sports &aacute; &Iacute;slandi </span>
        <span>ehf</span>
        <span>
          , a private limited company established under the laws of Iceland,
          having its principal place of business at{" "}
        </span>
        <span>Kalkofnsvegur</span>
        <span class="c4">&nbsp;2, 101 Reykjav&iacute;k, Iceland;</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Sideline Materials</span>
      </p>
      <p class="c0">
        <span class="c4">
          The visual interfaces, graphics, design, systems, methods,
          information, computer code, software, services, &ldquo;look and
          feel&rdquo;, organization, compilation of the content, code, data, any
          included content within platform such as exercise or workout
          descriptions, and all other elements of the Sideline Services;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c7"></span>
      </p>
      <p class="c0">
        <span class="c7">Sideline Services</span>
      </p>
      <p class="c0">
        <span>
          The System, Platform and all content, services and/or products
          available on or through the Platform enabling the Customer to conduct
          its operations, keep member registers, collect fees, communicate with
          members, schedule training sessions and matches, register and{" "}
        </span>
        <span>analyse</span>
        <span class="c4">
          &nbsp;performance results and athlete evaluations, analyze video of
          competition matches and other functions of a sports club, coach,
          trainer or other organization working with coaching and performance.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Special Terms</span>
      </p>
      <p class="c0">
        <span class="c4">
          any particulars, specifications and conditions by which the parties
          have agreed to deviate from these Terms;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Supplier</span>
      </p>
      <p class="c0">
        <span class="c4">Sideline</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">System</span>
      </p>
      <p class="c0">
        <span class="c4">
          the integrated computing solution for providing the Sideline Services,
          including applications, software, hardware, databases, interfaces,
          associated media, documentation, updates, new releases and other
          components or materials provided therewith;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c7"></span>
      </p>
      <p class="c0">
        <span class="c7">Template</span>
      </p>
      <p class="c0">
        <span class="c4">
          Workout programs, practice programs, descriptions about how to measure
          drills, tests, exercises and other metadata and reference data to help
          define other data (including activities).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">User</span>
      </p>
      <p class="c0">
        <span>A</span>
        <span class="c4">
          &nbsp;natural or legal person who uses the Account with a permission
          from the Customer.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">User Data</span>
      </p>
      <p class="c0">
        <span class="c4">
          Files, videos, audio, images, and any other digital data and
          information, which is subjected to the Sideline Services or otherwise
          inserted to the System by the Customer or User;
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Web Site</span>
      </p>
      <p class="c0">
        <span class="c4">
          the compilation of all web documents (including images, php and html
          files) made available via www.sidelinesports.com and xpsnetwork.com or
          their sub domains or domains with identical names under other top
          domains and owned by Supplier.
        </span>
      </p>
      <h2 class="c5" id="h.42e9m3vemd1x">
        <span class="c3">
          2. Authority to Enter into These Terms with Supplier
        </span>
      </h2>
      <p class="c0">
        <span class="c4">
          The use of the Sideline Services is subject to acceptance of these
          Terms. To accept these Terms for themselves or on behalf of an
          organization, a person must have the legal capacity to do so. In the
          case of an individual, the individual must be at least 16 years of age
          or have valid authorization from his/her legal representative or
          custodian. In the case of a legal entity, the entity must be duly
          incorporated and in good standing.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">The Terms are accepted electronically.</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          You may not, without Supplier&#39;s prior written consent, access the
          Sideline Services (i) for production purposes, (ii) if you are a
          competitor of Sideline, (iii) to monitor the availability, performance
          or functionality of the Sideline Services or (iv) for other
          benchmarking or competitive purposes.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Once accepted, these Terms remain effective until terminated as
          provided for herein.
        </span>
      </p>
      <h2 class="c5" id="h.4p0jtt274kdm">
        <span class="c3">3. Modifications to Terms</span>
      </h2>
      <p class="c0">
        <span class="c4">
          Supplier reserves the right, at its sole discretion, to change,
          modify, add, or remove portions of the Terms at any time by posting
          such changes on or through the Platform or the Sideline Services.
          Please check these Terms periodically for changes. Your continued use
          of the Sideline Services after such changes have been posted as
          provided above constitutes your binding acceptance of such changes.
          Such amended Terms will automatically be effective upon the earlier of
          (i) your continued use of the Sideline Services, or (ii) 30 days from
          posting of such modified Terms on or through the Platform.
          Notwithstanding the foregoing, the resolution of any dispute that
          arises between you and Supplier will be governed by the Terms in
          effect at the time such dispute arose.
        </span>
      </p>
      <h2 class="c5" id="h.r72oxjwvrj3r">
        <span class="c3">4. Our Responsibilities</span>
      </h2>
      <p class="c0">
        <span class="c7">4.1 Provisions of Sideline Services</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier will (a) make the Sideline Services available to Customer
          pursuant to these Terms, (b) provide applicable standard support for
          the Sideline Services to Customer at no additional charge, and/or
          upgraded support (for an additional charge, if applicable), (c) use
          commercially reasonable efforts to make the Sideline Services
          available 24 hours a day, 7 days a week, except for: (i) planned
          downtime , and (ii) any unavailability caused by circumstances beyond
          Supplier&#39;s reasonable control, including, for example, hardware
          failure, act of government, flood, fire, earthquake, civil unrest, act
          of terror, strike or other labor problem, Internet service provider
          failure or delay, or denial of service attack.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">
          4.2 Processing of personal data (including User Data)
        </span>
      </p>
      <p class="c0">
        <span>In order to provide the Services the </span>
        <span>Supplier</span>
        <span class="c4">
          &nbsp;will process personal data on behalf of the Customer as a data
          processor in the meaning of the General Data Protection Regulation
          (EU) 2016/679 (&ldquo;GDPR&rdquo;). The Customer acts as a data
          controller and on the basis of these Terms, the Customer instructs the
          Supplier to process personal data, including User Data, for the
          purposes of providing the Services.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span>
          All processing carried out by the Supplier is subject to a data
          processing agreement,{" "}
        </span>
        <span class="c8">
          <a
            class="c12"
            href="https://sidelinesports.com/dpa"
          >
            Data Processing Addendum
          </a>
        </span>
        <span>,</span>
        <span class="c4">
          &nbsp;which shall be considered part of these Terms.
        </span>
      </p>
      <h2 class="c5" id="h.r2ht2wwyeuxw">
        <span class="c3">5. Using the Sideline Services</span>
      </h2>
      <p class="c0">
        <span class="c7">5.1 Establishing an Account</span>
      </p>
      <p class="c0">
        <span class="c4">
          Certain features, functions, parts or elements of the Sideline
          Services can be used or accessed only by holders of an Account. The
          person who wishes to create an Account must
        </span>
      </p>
      <p class="c0">
        <span>complete the </span>
        <span>sign-up form on the Web Site</span>
        <span class="c4">
          &nbsp;or get an account created by Sideline; and accept these Terms.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          A User may be associated with multiple other Users and Accounts.The
          User associated with an Account must provide Supplier with true,
          accurate, current, and complete information about the User or Account
          and keep it up to date. A User can at most have 3 different logins,
          one for each type of user in the Services, as an Athlete, as a Coach
          and as a Family Member.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">5.2 Logging Into an Account </span>
      </p>
      <p class="c0">
        <span>
          Supplier shall provide Customer with the means to create login
          credentials that can allow Users to log into an Account. Each login
          credential must not be used by multiple persons.{" "}
        </span>
        <span>If Customer</span>
        <span class="c4">
          &nbsp;has designated several Users, each User will be provided with
          separate login credentials. A Customer and all Users are responsible
          for keeping confidential all login credentials associated with an
          Account. Customer must promptly notify Supplier of any disclosure,
          loss or unauthorized use of any login credentials.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">5.3. Termination of Account</span>
      </p>
      <p class="c0">
        <span>Customer</span>
        <span class="c4">
          &nbsp;may terminate these Terms at any time as provided in Section 16.
          Supplier shall at the request of the Customer permanently delete the
          Account within 10 days of the effective date of the request.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">5.4 Fees</span>
      </p>
      <p class="c0">
        <span class="c4">
          The use of an Account is subject to a Fee. Different rates apply to
          different Licenses. The applicable Fee is charged in advance on
          monthly or annual payment intervals, unless agreed otherwise between
          parties. All Fees are non-refundable, i.e. there are no refunds or
          credits for periods where the Customer or Users did not use an
          activated Account, used it only partially, or deactivated the Account
          or terminated these Terms during an ongoing payment interval. All Fees
          are exclusive of all taxes, levies or duties applicable under any
          applicable law, unless stated otherwise Customer is solely responsible
          for the payment of such taxes, levies or duties.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">5.5. Free Trial</span>
      </p>
      <p class="c0">
        <span>A Customer </span>
        <span class="c4">
          may be entitled to a Free Trial. If the period of Free Trial has
          expired, the Account will be automatically deactivated. In order to
          prevent deactivation or to reactivate the Account, the Customer is
          required to select a suitable Licensing plan and pay the first Fee. If
          the Customer does not pay the first Fee within 2 weeks as of the
          expiry of the Free Trial, Supplier has the right to permanently delete
          the Account, including all User Data therein.
        </span>
      </p>
      <h2 class="c5" id="h.y2njepyba90n">
        <span class="c3">6. Payment</span>
      </h2>
      <p class="c0">
        <span>
          The following provisions are applicable only if you purchase access to
          the Sideline Services directly{" "}
        </span>
        <span>from Supplier</span>
        <span class="c4">
          . If you purchase access to the Sideline Services through a Reseller,
          the payment terms are set forth in the agreement with your Reseller.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">6.1 Credit Card Authorization</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier may seek pre-authorization of Customer&#39;s credit card
          account prior to purchase of Sideline Services in order to verify that
          the credit card is valid and has the necessary funds or credit
          available to cover your purchase. As a Customer or representative of
          Customer you authorize such a credit card account to pay any amounts
          described herein, and authorize Supplier to charge all sums described
          in these Terms to such credit card account. You agree to provide
          Supplier updated information regarding your credit card account upon
          Supplier&#39;s request and any time the information earlier provided
          is no longer valid.
        </span>
      </p>
      <h2 class="c5" id="h.ewi43jbuin96">
        <span class="c3">7. User Data</span>
      </h2>
      <p class="c0">
        <span class="c7">7.1 Uploading User Data to Platform</span>
      </p>
      <p class="c0">
        <span class="c4">
          If the Customer uploads User Data to the Platform, such User Data and
          any processing of such User Data must be in compliance with these
          Terms and applicable law. All rights, title and interest in and to the
          User Data belong to the Customer or third persons (including Users,
          persons and Organizations) whether posted and/or uploaded by you or
          made available on or through the Sideline Services by Supplier. By
          uploading User Data to the Platform, User authorizes Supplier to
          process the User Data. The Customer is responsible for ensuring that:
        </span>
      </p>
      <ol class="c6 lst-kix_2h3uyatzokwu-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the Customer and any of the Users associated with the Account do not
            create, transmit, display or make otherwise available any User Data
            that violates the terms of these Terms, the rights of Supplier,
            other Users, persons or organizations or is harmful (for example
            viruses, worms, malware and other destructive codes), offensive,
            threatening, abusive, harassing, tortuous, defamatory, vulgar,
            obscene, invasive of another&#39;s privacy,hateful or otherwise
            unlawful; and
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <ol class="c6 lst-kix_2h3uyatzokwu-0" start="2">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the Customer and all of the Users associated with the Account have
            the necessary rights to use the User Data, including to insert it
            into the Platform and process it by means of the Account.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">7.2 No Guarantee of Accuracy</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier does not guarantee any accuracy with respect to any
          information contained in any User Data, and strongly recommends that
          you think carefully about what you transmit, submit or post to or
          through the Sideline Services. You understand that all information
          contained in User Data is the sole responsibility of the person from
          whom such User Data originated. This means that Customer, and not
          Supplier, is entirely responsible for all User Data that is uploaded,
          posted, transmitted, or otherwise made available through the Sideline
          Services.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">7.3 Unlawful User Data</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier is not obliged to pre-screen, monitor or filter any User Data
          or acts of its processing by the Customer in order to discover any
          unlawful nature therein. However, if such unlawful User Data or the
          action of its unlawful processing is discovered or brought to the
          attention of Supplier or if there is reason to believe that certain
          User Data is unlawful, Supplier has the right to:
        </span>
      </p>
      <ol class="c6 lst-kix_ihcx011ax0z9-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            notify the Customer of such unlawful User Data;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            deny its publication on the Web Site or its insertion to the System;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            demand that the Customer brings the unlawful User Data into
            compliance with these Terms and applicable law;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            temporarily or permanently remove the unlawful User Data from the
            Web Site or Account, restrict access to it or delete it.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          If Supplier is presented convincing evidence that the User Data is not
          unlawful, Supplier may, at its sole discretion, restore such User
          Data, which was removed from the Web Site or Account or access to
          which was restricted.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          In addition, in the event Supplier believes in its sole discretion
          User Data violates applicable laws, rules or regulations or these
          Terms, Supplier may (but has no obligation), to remove such User Data
          at any time with or without notice.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">7.4 Compelled Disclosure</span>
      </p>
      <p class="c0">
        <span>Supplier</span>
        <span>
          &nbsp;may disclose a Customer&#39;s confidential information to the
          extent compelled by law to do so. In such instances, Supplier will use
          commercially reasonable efforts to provide Customer with prior notice
          of the compelled disclosure (to the extent legally permitted) and
          Customer shall provide reasonable assistance, at its cost, if Customer
          wishes to contest the disclosure. If Supplier is compelled by law to
          disclose{" "}
        </span>
        <span>Customer&#39;s</span>
        <span class="c4">
          &nbsp;confidential information as part of a civil proceeding to which
          Supplier is a party, and Customer is not contesting the disclosure,
          Customer will reimburse Supplier for its reasonable cost of compiling
          and providing secure access to that confidential information.
        </span>
      </p>
      <h2 class="c5" id="h.5hygmye8wsbd">
        <span class="c3">8. Services</span>
      </h2>
      <p class="c0">
        <span class="c7">8.1 Use of the Sideline Services</span>
      </p>
      <p class="c0">
        <span class="c4">
          Subject to these Terms, and the payment of the applicable service Fee,
          Supplier grants Customer and its authorized Users a non-exclusive,
          non-transferable, non-sub-licensable license to use the Sideline
          Services to:
        </span>
      </p>
      <ol class="c6 lst-kix_ug5u4nc9zgjp-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            collect, store and organize User Data, such as add new Activities
            and Templates, generate reports based on User Data;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">modify and delete User Data;</span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            receive reasonable help and guidance from Supplier regarding the use
            of the Sideline Services.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">8.2 Technical Support</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier shall provide reasonable technical support to a User at the
          reasonable request of the Customer. Supplier shall respond to
          enquiries of support from a User utilizing the contacts set forth
          below as soon as reasonably possible.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Notwithstanding the foregoing, if you purchased access to the Sideline
          Services from a Reseller, then first-line technical support will be
          provided by the Reseller and not by the Supplier.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">8.3 Modifications to Service</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier reserves the right to modify the Sideline Services or any
          part or element thereof from time to time without prior notice,
          including, without limitation
        </span>
      </p>
      <ol class="c6 lst-kix_brm0sqng53jz-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            rebranding the Sideline Services at its sole discretion;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            ceasing providing or discontinuing the development any particular
            Sideline Service or part or element of the Platform temporarily or
            permanently;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            taking such action as is necessary to preserve Supplier&#39;s rights
            upon any use of the Sideline Services that may be reasonably
            interpreted as violation of Supplier&#39;s intellectual property
            rights, distribution of Internet viruses, worms, Trojan horses,
            malware, and other destructive activities or illegal activity.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span>As applicable, </span>
        <span>Customer</span>
        <span class="c4">
          &nbsp;may be notified of such modifications. Modifications, including
          change in applicable rates for the Sideline Services, will become
          effective thirty (30) days before the effective date of such
          modification.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          If the Customer does not accept the modification, the Customer shall
          notify Supplier or Reseller (if Customer purchased access to the
          Sideline Services from a Reseller) before the effective date of the
          modification, and these Terms will terminate on the effective date of
          the modification. The Customer&#39;s continued use of the Sideline
          Services, or any part or element thereof, after the effective date of
          a modification shall indicate its consent to the modifications.
          Supplier shall not be liable to the Customer or to any third person
          for any modification, suspension or discontinuance of the Sideline
          Services, or any part or element thereof.
        </span>
      </p>
      <h2 class="c5" id="h.kqr4sqbr15n9">
        <span class="c3">9. Restrictions</span>
      </h2>
      <p class="c0">
        <span class="c7">9.1 Prohibited Activities</span>
      </p>
      <p class="c0">
        <span>Customer</span>
        <span class="c4">
          &nbsp;may use the Sideline Services and any part or element thereof
          only in the scope, with the means and for purposes as identified in
          these Terms and applicable law. By way of example, the Customer is not
          allowed to:
        </span>
      </p>
      <ol class="c6 lst-kix_7zv18ku5ntgm-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            use the Sideline Services or any part or element thereof to commit a
            crime, breach any applicable law or entice or invite others to carry
            out such illegal actions;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            copy, duplicate, distribute, modify, adapt, hack, create derivative
            works, reverse engineer or decompile the Sideline Services or any
            part or element thereof, or attempt to extract the source code
            thereof, unless (i) it is expressly allowed under applicable law,
            and (ii) to the extent that the Supplier is not permitted by that
            applicable law to exclude or limit the foregoing rights;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            use the Sideline Services or any part or element thereof unless it
            has agreed to these Terms.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">9.2 Certain Uses Require Supplier Consent</span>
      </p>
      <p class="c0">
        <span class="c4">
          The Customer or any User may not, without Supplier&#39;s prior express
          written consent:
        </span>
      </p>
      <ol class="c6 lst-kix_u9go6fp1mkff-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            sell, resell, lease, license, sublicense, distribute, provide,
            disclose, divulge, exploit or otherwise grant Access or make the
            Sideline Services available in whole or in part to any third
            persons, unless such third person is another authorized User of the
            same Customer;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            use the Sideline Services or any part or element thereof in a scope,
            with means or for purposes other than those for which their
            functionality was created;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            use the Sideline Services or any part or element thereof by means of
            programs that send them automatic enquiries or requests, unless such
            program has been made available by Supplier;
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Specifically, a User cannot use the Sideline Services with another
          organization than the Customer who paid for the User&rsquo;s license
          to the Sideline Services, unless the other organization also is a
          Customer of Sideline with access to similar levels of licenses.
        </span>
      </p>
      <h2 class="c5" id="h.moq8i8ddgzjl">
        <span class="c3">10. Privacy</span>
      </h2>
      <p class="c0">
        <span>
          Supplier takes the privacy of its Customers and other third parties
          very seriously. Supplier&#39;s Privacy Policy at{" "}
        </span>
        <span class="c8">
          <a
            class="c12"
            href="https://sidelinesports.com/privacyPolicy"
          >
            https://sidelinesports.com/privacyPolicy
          </a>
        </span>
        <span class="c4">
          &nbsp;is hereby incorporated into these Terms by reference. Please
          read the Privacy Policy carefully as it governs Supplier&#39;s
          collection, use, and disclosure of personal data, including on
          Customers&rsquo; representatives, which the Supplier processes as a
          data controller in the meaning of the GDPR.
        </span>
      </p>
      <h2 class="c5" id="h.l7s64m97xkay">
        <span class="c3">11. Intellectual Property Rights</span>
      </h2>
      <p class="c0">
        <span class="c7">
          11.1 Sideline&#39;s Intellectual Property Rights in the Sideline
          Services
        </span>
      </p>
      <p class="c0">
        <span class="c4">
          The Sideline Services, Sideline Materials, Sideline trade names and
          trademarks, and any parts or elements thereof are solely and
          exclusively owned and operated by Supplier and its third party vendors
          and hosting partners. Sideline Materials are protected by copyright,
          trade dress, patent, trade secrets, and trademark laws, international
          conventions and treaties, and all other relevant intellectual property
          and proprietary rights laws. Supplier, its affiliates and licensors
          retain all right, title and interest in such Sideline Services,
          Sideline Materials, Sideline trade names and trademarks, and any parts
          or elements. Your use of the Sideline Services and Sideline Materials,
          and any parts or elements does not grant to you any ownership right or
          intellectual property rights therein. Any commercial or promotional
          distribution, publishing or exploitation of the Sideline Materials is
          strictly prohibited unless you have received the express prior written
          permission from Supplier or the otherwise applicable rights holder.
          Supplier reserves all rights to the Sideline Services, Sideline
          Materials and Sideline trade names and trademarks not expressly
          granted in the Terms.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">11.2 Content Owned by Sideline</span>
      </p>
      <p class="c0">
        <span class="c4">
          Subject to these Terms and the payment of the applicable service Fee,
          Supplier grants Customer and its authorized users a non-exclusive,
          non-transferable, non-sub-licensable license to download a single copy
          of any part of the Content solely for your personal, non-commercial
          use if you retain all copyright and proprietary notices that are
          contained in such part of the Content. You expressly acknowledge that
          you do not acquire any ownership rights by downloading any copyrighted
          material from or through the Platform or the Sideline Services. You
          shall not copy, distribute or publish any Content or any information
          obtained or derived therefrom except as permitted on or through the
          Sideline Services or as otherwise permitted by applicable law.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">11.3 Sideline Data</span>
      </p>
      <ol class="c6 lst-kix_3rdnq41x41u7-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span>Supplier</span>
          <span>
            &nbsp;may use User Data in an aggregated or anonymized format for
            research, educational and other similar purposes.{" "}
          </span>
          <span>Supplier</span>
          <span class="c4">
            &nbsp;may not otherwise use or display User Data without
            Customer&#39;s written consent. Supplier respects your right to
            exclusive ownership of your User Data. Unless specifically permitted
            by you, your use of the Sideline Services does not grant Supplier
            the license to use, reproduce, adapt, modify, publish or distribute
            the User Data created by you or stored in your Account for
            Supplier&#39;s commercial, marketing or any similar purpose.
            Customer expressly grants Supplier the right to use and analyze
            aggregate system activity data associated with use of the Sideline
            Services by Customer for the purposes of optimizing, improving or
            enhancing the way the Sideline Services operate, and to create new
            features and functionality in connection with the Sideline Services
            in the sole discretion of Supplier.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <ol class="c6 lst-kix_3rdnq41x41u7-0" start="2">
        <li class="c0 c1 li-bullet-0">
          <span>
            Supplier is authorized to display the Customer&#39;s logo on its
            website or in other promotional materials.{" "}
          </span>
          <span>Supplier</span>
          <span class="c4">
            &nbsp;may publicly acknowledge on its website and other platforms
            that the Customer is a customer, while maintaining confidentiality
            regarding the specifics of their agreement.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <ol class="c6 lst-kix_3rdnq41x41u7-0" start="3">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            Customer is solely responsible for its own User Data and the
            consequences of posting or publishing them on or through the
            Sideline Service. In connection with User Data, Customer affirms,
            represents, and warrants that: (i) Customer either owns its User
            Data or has the necessary licenses, rights, consents, and
            permissions to use and authorize the Suppliers to display or
            otherwise use the User Data under all patent, trademark, copyright,
            trade secrets, or other proprietary rights in and to your User Data
            in a manner consistent with the intended features of the Sideline
            Services and these Terms, and (ii) User Data, Supplier&#39;s or any
            Sideline Licensee&#39;s use of such User Data pursuant to these
            Terms, do not and will not: (a) infringe, violate, or misappropriate
            any third-party right, including any copyright, trademark, patent,
            trade secret, moral right, privacy right, right of publicity, or any
            other intellectual property or proprietary right; (b) violate any
            applicable law or regulation anywhere in the world; or (c) require
            obtaining a license from or paying any fees and/or royalties by
            Supplier to any third party for the performance of any Sideline
            Services Customer has chosen to be performed by Supplier or for the
            exercise of any rights granted in these Terms, unless Customer and
            Supplier otherwise agree.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">11.4 Feedback</span>
      </p>
      <p class="c0">
        <span class="c4">
          If Customer provides Suppliers with any comments, bug reports,
          feedback, or modifications for the Sideline Services
          (&ldquo;Feedback&rdquo;), Supplier shall have the right to use such
          Feedback at its discretion, including, but not limited to the
          incorporation of such suggested changes into the Sideline Services.
          Customer (as applicable) hereby grants Supplier a perpetual,
          irrevocable, non-exclusive, royalty free license under all rights
          necessary to incorporate, publish, reproduce, distribute, modify,
          adapt, prepare derivative works of, publicly display, publicly
          perform, exploit and use your Feedback for any purpose.
        </span>
      </p>
      <h2 class="c5" id="h.lwx8euyczvlc">
        <span class="c3">12. Third-Party Sites, Products and Services</span>
      </h2>
      <p class="c0">
        <span class="c4">
          The Sideline Services may include links to other websites or services
          (&ldquo;Linked Sites&rdquo;) solely as a convenience to Customers and
          Users. Unless otherwise specifically and explicitly indicated,
          Supplier does not endorse any such Linked Sites or the information,
          material, products, or services contained on or accessible through
          Linked Sites. Furthermore, Supplier makes no express or implied
          warranties with regard to the information, material, products, or
          services that are contained on or accessible through Linked Sites.
          ACCESS AND USE OF LINKED SITES, INCLUDING THE INFORMATION, MATERIAL,
          PRODUCTS, AND SERVICES ON LINKED SITES OR AVAILABLE THROUGH LINKED
          SITES, IS SOLELY AT YOUR OWN RISK.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Any content referred to as community provided is provided by third
          parties and not developed or maintained by Sideline. By using any
          community marked data or content, you acknowledge and agree that
          Sideline is not in any way responsible for the performance or damages
          caused by such community provided data or content.
        </span>
      </p>
      <h2 class="c5" id="h.kjzr1fc5zve0">
        <span class="c3">13. Disclaimers; No Warranty</span>
      </h2>
      <p class="c0">
        <span class="c4">
          UNLESS OTHERWISE EXPRESSLY STATED BY SUPPLIER, THE SIDELINE SERVICES,
          SIDELINE MATERIAL, AND ANY CONTENT, SERVICES, OR FEATURES MADE
          AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SIDELINE SERVICES ARE
          PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; WITHOUT
          WARRANTIES OF ANY KIND EITHER EXPRESS OR IMPLIED. TO THE FULLEST
          EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, SUPPLIER AND ITS
          AFFILIATES DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY
          RIGHTS, CORRECTNESS, ACCURACY, AND RELIABILITY.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          UNLESS OTHERWISE EXPRESSLY STATED BY SUPPLIER, SUPPLIER AND ITS
          AFFILIATES DO NOT WARRANT THAT THE SIDELINE SERVICES AND ANY CONTENT,
          USER DATA SERVICES, OR FEATURES MADE AVAILABLE IN CONJUNCTION WITH OR
          THROUGH THE SIDELINE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE,
          THAT DEFECTS WILL BE CORRECTED, OR THAT THE SIDELINE SERVICES AND ANY
          CONTENT, USER DATA, SERVICES, OR FEATURES MADE AVAILABLE IN
          CONJUNCTION WITH OR THROUGH THE SIDELINE SERVICES OR THE SERVER THAT
          MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          UNLESS OTHERWISE EXPRESSLY STATED BY SUPPLIER, SUPPLIER AND ITS
          AFFILIATES DO NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE
          USE OR THE RESULTS OF THE USE OF THE PLATFORM, THE SIDELINE SERVICES,
          SIDELINE MATERIAL OR ANY LINKED SITES, IN TERMS OF CORRECTNESS,
          ACCURACY, RELIABILITY, OR OTHERWISE.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          THE LAWS OF CERTAIN COUNTRIES AND STATES DO NOT ALLOW LIMITATIONS ON
          IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
          IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
          EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
          ADDITIONAL RIGHTS.
        </span>
      </p>
      <h2 class="c5" id="h.jmgf0zdkztip">
        <span class="c3">14. Indemnification</span>
      </h2>
      <p class="c0">
        <span class="c4">
          You agree to defend, indemnify and hold harmless Supplier and its
          affiliates, and their respective directors, officers, employees and
          agents, from any claims, losses, damages, injuries, liabilities,
          including attorney&#39;s fees, arising out of your use or misuse of
          the Sideline Services, Sideline Materials, representations made to the
          Supplier, its affiliates and/or third parties, violation of these
          Terms, violation of the rights of any other person or entity, or any
          breach of the foregoing representations, warranties, and covenants.
          Supplier reserves the right, at its own expense, to assume the
          exclusive defense and control of any matter for which you are required
          to indemnify Supplier, and you agree to cooperate with such defense of
          these claims.
        </span>
      </p>
      <h2 class="c5" id="h.1l3qnubbkuf0">
        <span class="c3">15. Limitation of Liability</span>
      </h2>
      <p class="c0">
        <span class="c7">15.1 No Liability</span>
      </p>
      <p class="c0">
        <span class="c4">
          Supplier shall not be liable to the Customer for any consequences
          resulting from:
        </span>
      </p>
      <ol class="c6 lst-kix_nz5rjffz4jcs-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            any modifications in these Terms, calculation and rates of Fees, the
            Sideline Services, Sideline Material, or any part or element thereof
            (including but not limited to Account), including any error,
            permanent or temporary interruption, discontinuance, suspension or
            other type of unavailability of the Sideline Services or Sideline
            Material;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            deletion of, corruption of, or failure to store any User Data;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            use of User Data by the Customer or any of the Users associated with
            the Account;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">upgrading or downgrading the current License;</span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            any disclosure, loss or unauthorized use of the login credentials of
            Customer or any associated User due to Customer&rsquo;s or
            User&#39;s failure to keep them confidential;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the Customer&#39;s or associated User&rsquo;s use of the Account or
            the Sideline Services by means of browsers other than those accepted
            or supported by the Supplier;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the application of any remedies against the Customer or associated
            Users by the Supplier, for example if the Customer or associated
            Users have committed a crime or conducted a breach of applicable law
            by using the Sideline Services or any part or element thereof;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the differences between technologies and platforms used for access,
            for example if certain features, functions, parts or elements of the
            Sideline Services are designed for use on a personal computer or
            laptop and do not function on a mobile platform or a tablet;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            the Supplier&#39;s application of the remedies described in these
            Terms, even if the reasonable grounds or legal basis for the
            application of these remedies turned out to be unfounded or invalid
            afterwards.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          In addition, Supplier and its affiliates shall not be liable to the
          Customer for any claim by any person, organization or third persons
          against the Customer arising out of the Customer&#39;s failure to:
        </span>
      </p>
      <ol class="c6 lst-kix_c17fprfqji8u-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            provide Supplier with accurate information about the Customer,
            associated Users or Account;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            notify Supplier of any reasons due to which a User does not have the
            right to use the Account on behalf of the Customer;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            provide any products which it has agreed to provide to such a person
            or organization (whether such failure arises as a result of
            Supplier&#39;s negligence, breach of these Terms or otherwise);
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">ensure the lawfulness of the User Data;</span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            obtain the necessary rights to use the User Data; or
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            abide by any of the restrictions described in these Terms.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">15.2 Limitation of Liability</span>
      </p>
      <p class="c0">
        <span class="c4">
          IN NO EVENT SHALL THE AGGREGATE LIABILITY OF SIDELINE AND ITS
          AFFILIATES ARISING OUT OF OR RELATED TO THESE TERMS EXCEED THE TOTAL
          AMOUNT PAID BY CUSTOMER HEREUNDER FOR THE SIDELINE SERVICES GIVING
          RISE TO THE LIABILITY IN THE SIX MONTHS PRECEDING THE FIRST INCIDENT
          OUT OF WHICH THE LIABILITY AROSE. THE FOREGOING LIMITATION WILL APPLY
          WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY
          OF LIABILITY, BUT WILL NOT LIMIT CUSTOMER&#39;S PAYMENT OBLIGATIONS
          UNDER THE &ldquo;PAYMENT&rdquo; SECTION ABOVE.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">
          15.3 Exclusion of Consequential and Related Damages
        </span>
      </p>
      <p class="c0">
        <span class="c4">
          IN NO EVENT WILL EITHER PARTY OR ITS AFFILIATES HAVE ANY LIABILITY
          ARISING OUT OF OR RELATED TO THESE TERMS FOR ANY LOST PROFITS,
          REVENUES, GOODWILL, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
          COVER, BUSINESS INTERRUPTION OR PUNITIVE DAMAGES, WHETHER AN ACTION IS
          IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY, EVEN IF
          A PARTY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES OR IF A PARTY&#39;S OR ITS AFFILIATES&#39; REMEDY OTHERWISE
          FAILS OF ITS ESSENTIAL PURPOSE. THE FOREGOING DISCLAIMER WILL NOT
          APPLY TO THE EXTENT PROHIBITED BY LAW.
        </span>
      </p>
      <h2 class="c5" id="h.o1jzrrieed6h">
        <span class="c3">16. Termination of These Terms</span>
      </h2>
      <p class="c0">
        <span class="c7">16.1 For Convenience</span>
      </p>
      <p class="c0">
        <span class="c4">
          These Terms may be terminated for convenience upon written notice to
          the other party as indicated in the &ldquo;Notice&rdquo; Section
          below:
        </span>
      </p>
      <ol class="c6 lst-kix_3zgbyfclaaq4-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            by the Customer any time by contacting Sideline;
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            by Supplier upon decision to end provision of the Sideline Services
            and close the Platform; or
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            immediately by either party, if proceedings are initiated for the
            other party&#39;s liquidation or insolvency or a negotiated
            settlement with the other party&#39;s creditors is concluded or an
            assignment is made on behalf of the other party for the benefit of
            creditors.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">16.2 For Default</span>
      </p>
      <p class="c0">
        <span class="c4">
          These Terms may be terminated for default upon written notice to the
          other party as indicated in the &ldquo;Notice&rdquo; Section below:
        </span>
      </p>
      <ol class="c6 lst-kix_s3bmc1wez3z6-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            by either party in case of breach of these Terms by the other party,
            if the breach has not been cured within 30 days of receipt of a
            notice from the non-breaching party; or
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            immediately by either party if the other party breaches its
            obligations, as applicable under Sections 11 [Intellectual Property
            Rights] and 14 [Indemnification] of these Terms.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">16.3 Effect of Termination</span>
      </p>
      <p class="c0">
        <span class="c4">Upon termination of these Terms,</span>
      </p>
      <ol class="c6 lst-kix_jz0fldkk0rlt-0 start" start="1">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            Supplier shall deactivate the Account. If the Customer has
            specifically requested for a deletion of the Account, Supplier shall
            fulfill such request within 10 days of its receipt of such request.
          </span>
        </li>
        <li class="c0 c1 li-bullet-0">
          <span class="c4">Customer must:</span>
        </li>
      </ol>
      <ol class="c6 lst-kix_jz0fldkk0rlt-1 start" start="1">
        <li class="c0 c10 li-bullet-0">
          <span class="c4">
            stop using and prevent the further usage of the Sideline Services,
            including, without limitation, the Platform;
          </span>
        </li>
        <li class="c0 c10 li-bullet-0">
          <span class="c4">
            pay any amounts owed to Supplier under these Terms; and
          </span>
        </li>
        <li class="c0 c10 li-bullet-0">
          <span class="c4">
            discharge any liability incurred by the Customer before under these
            Terms prior to their termination; and
          </span>
        </li>
      </ol>
      <ol class="c6 lst-kix_jz0fldkk0rlt-0" start="3">
        <li class="c0 c1 li-bullet-0">
          <span class="c4">
            The following provisions shall survive the termination of these
            Terms: Sections 1, 7.4, 9, 10, 11, 13, 14, 15, 17 and 18.
          </span>
        </li>
      </ol>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">16.4 Remedies</span>
      </p>
      <p class="c0">
        <span class="c4">
          If Supplier has reasonable grounds to believe that the Customer&#39;s
          or any associated User&rsquo;s use of the Sideline Services, including
          the Account may harm any third persons, Supplier has the right to take
          adequate measures under its control to prevent, stop and eliminate the
          harm, where possible, in order to protect those third persons.
        </span>
      </p>
      <h2 class="c5" id="h.uaazz0vtoa3g">
        <span class="c3">17. Who You Are Contracting With</span>
      </h2>
      <p class="c0">
        <span class="c7">17.1 General</span>
      </p>
      <p class="c0">
        <span>
          The Supplier with whom Customer is contracting is Sideline Sports a
          Islandi{" "}
        </span>
        <span>ehf</span>
        <span class="c4">.</span>
      </p>
      <p class="c0">
        <span>Postal Address: </span>
        <span>Kalkofnsvegur</span>
        <span class="c4">&nbsp;2, 101 Reykjav&iacute;k, ICELAND</span>
      </p>
      <p class="c0">
        <span class="c4">E-mail: info@sidelinesports.com</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">17.2 Governing Law and Jurisdiction</span>
      </p>
      <p class="c0">
        <span class="c4">
          In the event of a dispute, controversy or claim arising out of or in
          relation to these Terms, including but not limited to the formation,
          validity, breach or termination thereof, the parties shall attempt to
          solve the matter amicably in mutual negotiations. In the event a
          mutually acceptable resolution cannot be reached within a reasonable
          time, either party will be entitled to seek all available remedies,
          including legal remedies subject to the terms and conditions set forth
          below. Notwithstanding the foregoing and subject to the terms and
          conditions set forth below, either party may seek injunctive relief
          with respect to any disputed matter to the extent possible under
          applicable law. Should an amicable settlement between parties not be
          possible, the dispute shall be finally solved in court as designated
          herein subject to the terms and conditions set forth below.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          The United Nations Convention on Contracts for the International Sale
          of Goods (Vienna Convention of 1980) shall not be applied to these
          Terms. Any questions relating to these Terms which are not expressly
          or implicitly settled by the provisions contained in these Terms shall
          be governed by and construed in accordance with the following:
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">
          Client&#39;s Domicile:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c4">All areas</span>
      </p>
      <p class="c0 c2">
        <span class="c7"></span>
      </p>
      <p class="c0">
        <span class="c7">
          Governing Law; Dispute
          Resolution:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c4">
          These Terms (and any further rules, policies or guidelines
          incorporated by reference therein) shall be governed by and construed
          in accordance with the laws of Iceland.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">Courts having Exclusive Jurisdiction:</span>
      </p>
      <p class="c0">
        <span class="c4">H&eacute;ra&eth;sd&oacute;mur Reykjav&iacute;kur</span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Use of the Sideline Services is not authorized in any jurisdiction
          that does not give effect to all provisions of these Terms, including
          without limitation, this section.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">
          Notwithstanding the foregoing, you and the Suppliers agree that
          nothing herein shall be deemed to waive, preclude, or otherwise limit
          either party&#39;s right to (i) pursue enforcement actions through
          applicable federal, state, or local agencies where such actions are
          available, (ii) seek injunctive relief in a court of law, or (iii) to
          file suit in a court of law to address intellectual property
          infringement claims.
        </span>
      </p>
      <h2 class="c5" id="h.byvqoeolia4y">
        <span class="c3">18. General Provisions</span>
      </h2>
      <p class="c0">
        <span class="c7">18.1 Relationship of the Parties</span>
      </p>
      <p class="c0">
        <span class="c4">
          Unless otherwise specified, the parties will act solely as independent
          contractors. These Terms shall not be construed as creating an agency,
          partnership, joint venture, fiduciary duty, or any other form of legal
          association between the Customer and either Supplier, and the Customer
          shall not represent to the contrary, whether expressly, by
          implication, appearance or otherwise. These Terms are not for the
          benefit of any third parties.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">18.2 Severability</span>
      </p>
      <p class="c0">
        <span class="c4">
          If any term, condition or provision of these Terms is held to be
          invalid, unenforceable or illegal in whole or in part for any reason,
          that provision shall be enforced to the maximum extent permissible so
          as to affect the intent of the parties. The validity and
          enforceability of the remaining terms, conditions or provisions, or
          portions of them, shall not be affected.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">18.3 Entire Agreement</span>
      </p>
      <p class="c0">
        <span class="c4">
          These Terms (including the Data Processing Agreement) constitute the
          entire agreement between Customer and Supplier regarding
          Customer&#39;s use of the Sideline Services and supersedes all prior
          and contemporaneous agreements, proposals or representations, written
          or oral, concerning its subject matter. Except as otherwise provided
          herein, no modification, amendment, or waiver of any provision of
          these Terms will be effective unless in writing and signed by the
          party against whom the modification, amendment or waiver is to be
          asserted.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">18.4 Assignment</span>
      </p>
      <p class="c0">
        <span>Customer</span>
        <span class="c4">
          &nbsp;may not, directly or indirectly, in whole or in part, by
          operation of law or otherwise, assign or transfer these Terms or
          delegate any of its rights and/or obligations under these Terms
          without Supplier&#39;s prior written consent. Any attempted
          assignment, transfer or delegation without such prior written consent
          will be void and unenforceable. Notwithstanding the foregoing, the
          Customer, or its permitted successive assignees or transferees, may
          assign or transfer these Terms or delegate any rights or obligations
          hereunder without consent: (1) to any entity controlled by, or under
          common control with the Customer, or its permitted successive
          assignees or transferees; or (2) in connection with a merger,
          reorganization, transfer, sale of assets or product lines, or change
          of control or ownership of the Customer, or its permitted successive
          assignees or transferees.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">18.5 No Waiver</span>
      </p>
      <p class="c0">
        <span class="c4">
          Failure of either Party to exercise or enforce any provision of or any
          of its rights under these Terms shall not be deemed a waiver of future
          enforcement of that or any other provision or right.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c7">18.6 Notices</span>
      </p>
      <p class="c0">
        <span class="c4">
          Except as otherwise specified in these Terms, all notices related to
          these Terms will be in writing and will be effective upon (a) personal
          delivery, (b) the second business day after mailing, or (c), except
          for notices of termination or an indemnifiable claim (&ldquo;Legal
          Notices&rdquo;), which shall clearly be identifiable as Legal Notices,
          the day of sending by email. Billing-related notices to you will be
          addressed to the relevant billing contact designated by you. All other
          notices to you will be addressed to the relevant Services system
          administrator designated by you.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c4"></span>
      </p>
      <p class="c0">
        <span class="c4">Last updated: February 20, 2024</span>
      </p>
    </div>
  )
}
